
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import StudentSections from '@/components/sections/admin/Student.vue'
import Header from '@/components/sections/Header.vue'

export default defineComponent({
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
    })
  },
  name: 'Student',
  components: {
    StudentSections,
    Header
  }
})
